import {
  type LinksFunction,
  type LoaderFunctionArgs,
  type MetaFunction,
} from '@remix-run/node';
import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from '@remix-run/react';
import { DateTime } from 'luxon';
import CozIconSVG from '~/assets/svg/cozLogo';
import LoginForm from '~/components/LoginForm';
import LogoutForm from '~/components/LogoutForm';
import printStyles from '~/styles/print.css?url';
import styles from '~/styles/tailwind.css?url';
import { authenticator, formatProfile } from '~/utils/auth.server';
import { getEnv } from '~/utils/env.server';
import { fetcher } from '~/utils/fetcher';

const { API_URL } = process.env;

export const links: LinksFunction = () => {
  return [
    { rel: 'stylesheet', href: styles },
    { rel: 'stylesheet', href: printStyles },
  ];
};

export const meta: MetaFunction<typeof loader> = () => [
  { title: 'City of Zen' },
  { name: 'description', content: 'One stop for all your quoting needs' },
];

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const accessToken = await authenticator.isAuthenticated(request);

  try {
    if (accessToken) {
      const response = await fetcher(
        `${API_URL}/mainUserProfile`,
        {
          method: 'GET',
        },
        accessToken as string,
      );

      if (!response.ok) {
        const res = await response.json();
        console.log('Request ID', res.requestId);
        throw new Error(
          `Response status: ${response.status}\nResponse statusText: ${response.statusText}\nRequestId: ${res.requestId}`,
        );
      }

      const userProfile = await response.json();
      const formattedProfile = formatProfile(userProfile);
      return {
        userProfile: formattedProfile,
        ENV: getEnv(),
      };
    }

    return {
      ENV: getEnv(),
      userProfile: null,
    };
  } catch (error: unknown) {
    // @ts-expect-error - unknown error type, ts doesn't like
    console.log('Error: ', error?.message);
    // @ts-expect-error - unknown error type, ts doesn't like
    throw new Response(error?.message || 'Something went wrong', {
      status: 403,
    });
  }
};

export default function App() {
  const { ENV, userProfile } = useLoaderData<typeof loader>();
  return (
    <html lang="en" data-theme="cupcake" className="h-screen">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="flex h-screen flex-col">
        <header className="flex bg-zinc-900 px-8 py-4">
          <Link to="/" className="flex grow items-center text-xl text-white">
            <CozIconSVG />
            City Of Zen
          </Link>
          <nav className="grow-0">
            <ul>
              <li>{userProfile ? <LogoutForm /> : <LoginForm />}</li>
            </ul>
          </nav>
        </header>
        <script
          suppressHydrationWarning
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(ENV)}`,
          }}
        />
        <Outlet />
        <footer className="px-8 py-4">
          <span id="copyright" className="text-xs">
            &copy;City of Zen {DateTime.now().year}
          </span>
        </footer>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const shouldRevalidate = () => {
  // Skip revalidation
  return false;
};
